<template>
  <div class="order-qrcode">
    <h2>訂單詳情</h2>
    <qrcode-vue
      :value="qrcodeValue"
      :size="180"
      :level="qrcode_level"
      :renderAs="qrcode_renderAs"
      class="small-code"
      v-if="id"
    />
    <div v-else style="height: 180px"></div>
  </div>
</template>

<script>
import { computed } from "vue";
import QrcodeVue from "qrcode.vue";
export default {
  components: {
    QrcodeVue,
  },
  props: {
    id: {
      type: Number,
    },
    qrcode_level: {
      type: String,
      default: "L",
    },
    qrcode_renderAs: {
      type: String,
      default: "canvas",
    },
    qrcode_class: {
      type: String,
      default: "qrcode",
    },
  },
  setup(props) {
    const qrcodeValue = computed(() => {
      return JSON.stringify({ type: "order", order_id: props.id });
    });

    return {
      qrcodeValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.order-qrcode {
  text-align: center;
  padding: 32px 0;
  h2 {
    color: var(--color-primary);
    margin-bottom: 24px;
  }
}
</style>
