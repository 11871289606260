<template>
  <div
    class="cart-summary row no-gutters justify-content-end"
    v-for="discount_item in currentOrder.order_discount"
    :key="discount_item.id"
  >
    <!-- 優惠卷 -->
    <template v-if="discount_item.type === 0">
      <div style="width: 100%">
        <cart-coupon
          :modelValue="discount_item.discount_description"
          :discountPrice="discount_item.discount_price"
        />
      </div>
    </template>
    <!-- 現場折價 -->
    <template v-else-if="discount_item.type === 3">
      <div class="col-auto p-2">
        <span>現場折價：</span>
        <span class="text-main">
          {{ formatMoney(discount_item.discount_price) }}
        </span>
        <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
      </div>
    </template>
    <!-- 整筆改價 -->
    <template v-else-if="discount_item.type === 4">
      <div class="col-auto p-2">
        <span>整筆改價</span>
        <span class="text-main">
          {{ formatMoney(discount_item.discount_price) }}
        </span>
        <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
      </div>
    </template>
  </div>
  <div
    class="cart-summary row no-gutters justify-content-end"
    v-if="currentOrder.order_payment?.info?.payment_fee > 0"
  >
    <div class="col-auto p-2">
      <span>額外費用：</span>
      <span class="text-main"
        >${{ formatMoney(currentOrder.order_payment?.info?.payment_fee) }}</span
      >
      <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
    </div>
  </div>
  <div
    class="cart-summary row no-gutters justify-content-end"
    v-if="currentOrder.redeem_member_points !== 0"
  >
    <div class="col-auto p-2">
      <span>積點折抵：</span>
      <span class="text-main">
        -${{ formatMoney(Math.abs(currentOrder.redeem_member_points)) }}
      </span>
      <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
    </div>
  </div>

  <div
    class="cart-summary row no-gutters justify-content-end"
    v-if="currentOrder.order_shipping"
  >
    <div class="col-auto p-2">
      <span>運費：</span>
      <span class="text-main"
        >${{ formatMoney(currentOrder.order_shipping.shipping_fee) }}</span
      >
      <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
    </div>
  </div>
  <div class="cart-summary row no-gutters justify-content-end">
    <div class="col-auto p-2">
      <span>總計：</span>
      <span class="text-main">${{ formatMoney(currentOrder.total) }}</span>
      <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
    </div>
  </div>
</template>

<script>
import { formatMoney } from "@/utils/tools";
// import CartCoupon from "@/components/cart-coupon/index.vue";

export default {
  // components: {
  //   CartCoupon,
  // },
  props: {
    currentOrder: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      formatMoney,
    };
  },
};
</script>

<style lang="scss" scoped></style>
