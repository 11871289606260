import { isAfter, addDays, subMinutes, parseISO } from "date-fns";
import { zonedTimeToUtc, format } from "date-fns-tz";
import storage from "@u/storage";
storage.config = { type: "session" };

export const additionalItemPriceFormat = (price) => {
  if ((price + "").charAt(0) === "-")
    price = (price + "").replace(/[-]/g, "-$");
  else price = "+$" + price;
  return price;
};

export const formatMoney = (
  number,
  decimals = 0,
  decPoint = ".",
  thousandsSep = ","
) => {
  number = (number + "").replace(/[^0-9+-Ee.]/g, "");
  let n = !isFinite(+number) ? 0 : +number;
  let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  let sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
  let dec = typeof decPoint === "undefined" ? "." : decPoint;
  let s = "";
  let toFixedFix = function (n, prec) {
    let k = Math.pow(10, prec);
    return "" + Math.ceil(n * k) / k;
  };
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  let re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, "$1" + sep + "$2");
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};

export const createTimeSlots = (slotConfig) => {
  // Getting values from slotConfig using destructuring
  const {
    configSlotHours,
    configSlotMinutes,
    configSlotPreparation,
    configPreparationMin,
    timeArr,
    configDefaultDate,
  } = slotConfig;

  // This is the default date that we are using to make use of javascript date functions
  // slotsArray will hold final slots
  // _timeArrStartTime is used to store start time date object from the timeArr
  // _timeArrEndTime is used to store end time date object from the timeArr
  // _tempSlotStartTime is used to create slots by adding config values and check that the time is less than the end time and lies withing the duration specified
  // _startSlot holds value of start date time of slot
  // _endSlot holds value of end date time of slot
  let my_date = new Date();
  if (configDefaultDate !== undefined) {
    my_date = configDefaultDate;
  }
  let defaultDate = my_date.toISOString().substring(0, 10);
  let defaultNowDate = new Date().toISOString().substring(0, 10);
  let now =
    defaultNowDate +
    "T" +
    (new Date().getHours() < 10 ? "0" : "") +
    new Date().getHours() +
    ":" +
    (new Date().getMinutes() < 10 ? "0" : "") +
    new Date().getMinutes();
  let slotsArray = [];
  let _timeArrStartTime;
  let _timeArrEndTime;
  let _tempSlotStartTime;
  let _endSlot;
  let _startSlot;

  // Loop over timeArr
  for (let i = 0; i < timeArr.length; i++) {
    // Creating time stamp using time from timeArr and default date
    _timeArrStartTime = new Date(
      defaultDate + "T" + timeArr[i].startTime
    ).getTime();
    _timeArrEndTime = new Date(
      defaultDate + "T" + timeArr[i].endTime
    ).getTime();
    _tempSlotStartTime = _timeArrStartTime;

    // Loop around till _tempSlotStartTime is less end time from timeArr
    while (
      new Date(_tempSlotStartTime).getTime() <
      new Date(_timeArrEndTime).getTime()
    ) {
      _endSlot = new Date(_tempSlotStartTime);
      _startSlot = new Date(_tempSlotStartTime);

      //Adding minutes and hours from config to create slot and overiding the value of _tempSlotStartTime
      _tempSlotStartTime = _endSlot.setHours(
        parseInt(_endSlot.getHours()) + parseInt(configSlotHours)
      );
      _tempSlotStartTime = _endSlot.setMinutes(
        parseInt(_endSlot.getMinutes()) + parseInt(configSlotMinutes)
      );
      // Check _tempSlotStartTime is less than end time after adding minutes and hours, if true push into slotsArr
      if (
        new Date(_tempSlotStartTime).getTime() <=
        new Date(_timeArrEndTime).getTime()
      ) {
        // (new Date(_tempSlotStartTime) - new Date(now)) / (60 * 1000) >= 15
        // console.log("_startSlot:", new Date(_startSlot));
        // console.log("_endSlot:", new Date(_endSlot));
        // console.log("_tempSlotStartTime:", new Date(_tempSlotStartTime));
        // console.log("_timeArrEndTime:", new Date(_timeArrEndTime));
        // console.log(
        //   "與現在時間相比:",
        //   (new Date(_endSlot) - new Date(now)) / (60 * 1000)
        // );
        // console.log("configPreparationMin", configPreparationMin);
        //大於備貨時間
        if (
          (new Date(_endSlot) - new Date(now)) / (60 * 1000) >=
          configPreparationMin
        ) {
          // DateTime object is converted to time with the help of javascript functions
          // If you want 24 hour format you can pass hour12 false
          if (
            (new Date(_startSlot) - new Date(now)) / (60 * 1000) <
            configPreparationMin
          ) {
            slotsArray.push({
              timeSlotEnd: _endSlot.toLocaleTimeString("en-US", {
                hourCycle: "h23",
                hour: "2-digit",
                minute: "2-digit",
              }),
            });
          } else {
            slotsArray.push({
              timeSlotStart: new Date(_startSlot).toLocaleTimeString("en-US", {
                hourCycle: "h23",
                hour: "2-digit",
                minute: "2-digit",
              }),
              timeSlotEnd: _endSlot.toLocaleTimeString("en-US", {
                hourCycle: "h23",
                hour: "2-digit",
                minute: "2-digit",
              }),
            });
          }
        }
      }

      //preparation time is added in last to maintain the break period
      _tempSlotStartTime = _endSlot.setMinutes(
        _endSlot.getMinutes() + parseInt(configSlotPreparation)
      );
    }
  }
  return slotsArray;
};

// 可下單的時間物件
export const getPickDateTimeObject = (
  weeks,
  preparation_min,
  closed_status,
  closed_min
) => {
  // console.log(  weeks,
  //   preparation_min,
  //   closed_status,
  //   closed_min)
  const day_of_week = format(new Date(), "i");
  const today_format = format(new Date(), "Y-MM-dd");
  const now = parseISO(format(new Date(), "Y-MM-dd HH:mm:ss"));
  let pickDate = [];
  if (weeks === undefined) return false;
  weeks.forEach(function (week) {
    let week_ch
    switch (week.day) {
      case 1:
          week_ch = '一'
        break;
      case 2:
          week_ch = '二'
        break;
      case 3:
          week_ch = '三'
        break;
      case 4:
          week_ch = '四'
        break;
      case 5:
          week_ch = '五'
        break;
      case 6:
          week_ch = '六'
        break;
      case 7:
          week_ch = '日'
        break;
    }
    let timeArrForWeek = [];
    let next_day =
      week.day >= day_of_week
        ? week.day - day_of_week
        : 7 + (week.day - day_of_week);
    week.hours.forEach(function (hour) {
      //today
      let open_time = parseISO(
        today_format + " " + hour.open.replace("24:00", "23:59")
      );
      let close_time = parseISO(
        today_format + " " + hour.close.replace("24:00", "23:59")
      );
      if (week.day == day_of_week) {
        //營業結束時間前多久不可下單
        if (closed_status === 1) {
          close_time = subMinutes(close_time, closed_min);
        }
        //結束超過當下時間，不顯示
        if (isAfter(close_time, now)) {
          timeArrForWeek.push({
            startTime: format(open_time, "HH:mm"),
            endTime: format(close_time, "HH:mm"),
          });
        }
      } else {
        timeArrForWeek.push({
          startTime: format(open_time, "HH:mm"),
          endTime: format(close_time, "HH:mm"),
        });
      }
    });
    let createTimeSlotsArr = createTimeSlots({
      configSlotHours: "00",
      configSlotMinutes: "15",
      configSlotPreparation: "15",
      configPreparationMin: preparation_min,
      timeArr: timeArrForWeek,
      configDefaultDate: addDays(now, next_day),
    });
    if (createTimeSlotsArr.length > 0) {
      let key = format(addDays(now, next_day), "Y-MM-dd");
      key = `${key} (${week_ch})`
      pickDate[key] = createTimeSlotsArr;
    }
  });

  pickDate = Object.keys(pickDate)
    .sort()
    .reduce((r, k) => ((r[k] = pickDate[k]), r), {});

  return pickDate;
};

// option: 可用的取貨/用餐時間（隨著營業時間間增長）
export const getPickTimeOptions = (pickDateObj, pick_date) => {
  // console.log(pickDateObj, pick_date)
  let optionsPickTime = [];
  if (Object.prototype.hasOwnProperty.call(pickDateObj, pick_date)) {
    pickDateObj[pick_date].forEach((timers, key) => {
      if (timers.timeSlotStart !== "" && timers.timeSlotStart !== undefined) {
        optionsPickTime.push({
          value: timers.timeSlotStart,
          text: timers.timeSlotStart,
        });
      }
      if (timers.timeSlotEnd !== "" && timers.timeSlotEnd !== undefined) {
        optionsPickTime.push({
          value: timers.timeSlotEnd,
          text: timers.timeSlotEnd,
        });
      }
    });
  }
  return optionsPickTime;
};

export const formateTime = (my_time) => {
  if (my_time !== null)
    return format(zonedTimeToUtc(my_time, "UTC"), "yyyy-MM-dd HH:mm:ss");
};

export const formateToMinute = (my_time) => {
  if (my_time !== null)
    return format(zonedTimeToUtc(my_time, "UTC"), "yyyy-MM-dd HH:mm");
};
