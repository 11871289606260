<template>
  <div class="img-block col">
    <img :src="image_url || defaultImg" alt="product" />
  </div>
</template>

<script>
import defaultImg from "@/assets/images/products-img.svg";
export default {
  props: {
    image_url: String,
  },
  data() {
    return {
      defaultImg,
    };
  },
};
</script>
