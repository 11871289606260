<template>
  <div>
    <!-- <template v-if="isMemberVip"> -->
    <div class="product-price" v-if="priceType === 1">
      <span class="vip-label">VIP價</span>
      <span class="price vip-price">{{ formatMoney(item.vip_price) }}</span>
      <span class="original-price"> (原價:{{ formatMoney(item.price) }}) </span>
    </div>
    <div class="product-price" v-if="priceType === 2">
      <span class="vip-label">VIP價</span>
      <span class="price vip-price">{{ formatMoney(item.vip_price) }}</span>
      <span class="vip-only">僅限vip會員商品</span>
    </div>
    <div class="product-price" v-if="priceType === 3">
      <span class="vip-label" v-if="item.cartVip">VIP價</span>
      <span class="price">{{ formatMoney(item.price) }}</span>
    </div>
    <!-- </template> -->
    <!-- <template v-else>
      <div class="product-price">
        <span class="price">{{ formatMoney(item.price) }}</span>
      </div>
    </template> -->
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { formatMoney } from "@/utils/tools";

export default {
  name: "ProductPrice",
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    // const store = useStore();

    // const isMemberVip = computed(() => {
    //   return Number(store.state.member?.homepage?.is_vip) === 1;
    // });

    // 1為vip價格
    // 2為vip商品
    // 3為一般商品
    const priceType = computed(() => {
      if (props.item.is_vip === 1) {
        return 2;
      } else {
        if (props.item.vip_price !== undefined) {
          return 1;
        }
        return 3;
      }
    });

    return {
      priceType,
      formatMoney,
      // isMemberVip,
    };
  },
};
</script>

<style lang="scss" scoped>
.product-price {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;

  & > span {
    margin-right: 12px;
  }

  .vip-label {
    background-color: var(--color-red);
    color: var(--color-white);
    padding: 0 2px 0 6px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -10px;
      width: 10px;
      height: 100%;
      border-style: solid;
      border-width: 10px 0 10px 8px;
      border-color: transparent transparent transparent var(--color-red);
    }
  }

  .original-price {
    color: var(--color-dark);
    font-size: 12px;
  }
  .vip-only {
    border: 1px solid var(--color-red);
    color: var(--color-red);
    padding: 0 6px;
    font-size: 12px;
    border-radius: 99px;
  }

  .vip-price {
    color: var(--color-red);
  }
}
</style>
